import { computed } from 'vue';
import { useStore } from 'vuex';
import * as mapKeys from '@keys/index';

type MapModeObj = { name: TmMapMode; dataMode: TmDataMode; modelTypes?: TmModelType[] };

const mapModes: MapModeObj[] = [
  { name: mapKeys.MP_HISTORICAL_VIEWER, dataMode: 'historical' },
  { name: mapKeys.MP_LIVE_VIEWER, dataMode: 'live' },
  { name: mapKeys.MP_MODEL_STA_VIEWER, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_MODEL_STA_COMPARISON, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_MODEL_DTA_VIEWER, dataMode: 'model', modelTypes: ['DTA'] },
  { name: mapKeys.MP_MODEL_DTA_COMPARISON, dataMode: 'comparison', modelTypes: ['DTA'] },
  { name: mapKeys.MP_HISTORICAL_COMPARISON, dataMode: 'comparison' },
  { name: mapKeys.MP_COMBINED_COMPARISON, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_SHORTEST_SELECT, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_SHORTEST_VIEW, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_GENERATOR_DRAW, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_GENERATOR_NEW_NODES, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_GENERATOR_SELECT, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_GENERATOR_EXIST_NODES, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_LINK_NODES, dataMode: 'model', modelTypes: ['STA', 'DTA'] },
  { name: mapKeys.MP_LINK_MODIFY, dataMode: 'model', modelTypes: ['STA', 'DTA'] },
  { name: mapKeys.MP_LINK_SELECT, dataMode: 'model', modelTypes: ['STA', 'DTA'] },
  { name: mapKeys.MP_NODE_DRAW, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_NODE_SELECT, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_NODE_LINKS, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_MODEL_STA_DATASET, dataMode: 'model', modelTypes: ['STA'] },
  { name: mapKeys.MP_HISTORICAL_AGGREGATION, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_H_AGGREGATION_COMPARISON, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_MODEL_STA_AGGREGATION, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_MODEL_STA_AGGREGATION_COMPARISON, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_LINK_EXPORT_SELECT, dataMode: 'historical', modelTypes: ['STA'] },
  { name: mapKeys.MP_LINK_EXPORT_SELECT_AGGREGATION, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_DATASET_MATRIX_COMPARISON, dataMode: 'comparison', modelTypes: ['STA'] },
  { name: mapKeys.MP_MATRICES_COMPARISON, dataMode: 'comparison', modelTypes: ['STA'] },
];

export default function useMapModes() {
  const store = useStore();
  const modelType = computed<TmModelType>(() => store.state.scenarios.model.type);
  const currentMapMode = computed<TmMapMode>(() => store.state.map.mapMode);
  const dataMode = computed<TmDataMode>(() => store.getters['map/getDataMode']);
  const brokenMapModes = computed<TmMapMode[]>(() => store.state.map.brokenMapModes || []);

  return {
    currentMapMode,
    getMapModes: (dm = dataMode.value, mt = modelType.value) => _getMapModes(dm, mt),
    mapModes: computed(() => _getMapModes(dataMode.value, modelType.value)),
    brokenMapModes,
  };
}

function _getMapModes(dataMode: TmDataMode | null = null, modelType: TmModelType | null = null): TmMapMode[] {
  const matchesModelType = (mts?: TmModelType[]) => !modelType || !mts || mts.includes(modelType);
  const matchesDataMode = (dm?: TmDataMode) => !dataMode || dm === dataMode;

  return mapModes.reduce((modes: TmMapMode[], props) => {
    if (matchesModelType(props.modelTypes) && matchesDataMode(props.dataMode)) modes.push(props.name);
    return modes;
  }, []);
}

export function getDataModeByMapMode(mapMode: TmMapMode): TmDataMode {
  const mapModeConfig = mapModes.find((mapModeEntry) => mapModeEntry.name === mapMode);
  return mapModeConfig?.dataMode ?? 'model';
}
