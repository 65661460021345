<template>
  <aside>
    <!-- left bar with scenario list -->
    <prime-sidebar v-model:visible="scenarioBarOpened">
      <template #header>
        <tm-navigation
          :route-links="[
            { name: 'user', icon: 'ri-user-line', tooltip: 'user' },
            { name: 'settings', icon: 'ri-settings-3-line', tooltip: 'settings' },
          ]"
          :show-data-modes="false"
          :show-map-controls="false"
          @navigated="
            scenarioBarOpened = false;
            footBarOpened = false;
          "
        />
      </template>
      <tm-scenario-list />
    </prime-sidebar>

    <!-- footer bar with widgets -->
    <prime-sidebar
      v-model:visible="footBarOpened"
      position="bottom"
      :style="`height: ${footBarHeight}px`"
      :modal="false"
    >
      <template #header>
        <div v-touch:drag.stop="dragFootBar">
          <div v-touch="resetFootBar" class="footer-handle"></div>
          <span class="footer-heading">{{ $t('navigation.data selection') }}</span>
        </div>
        <tm-navigation :show-widgets="false" :show-routes="false" :show-map-controls="false" />
      </template>
      <tm-widgets />
    </prime-sidebar>

    <!-- content loader -->
    <prime-progress-bar v-if="barLoading" mode="indeterminate" class="progressbar" />

    <!-- alert messages -->
    <prime-toast position="bottom-center" />

    <!-- confirmation dialog -->
    <prime-confirm-dialog />

    <!-- custom tm dialog -->
    <tm-dialog />
  </aside>

  <header>
    <div class="p-component p-d-flex p-ai-center">
      <tm-button type="link" icon="pi pi-bars" @click="scenarioBarOpened = true" />
      <div v-if="activeScenario.id" class="header-body">
        <div class="p-text-left">
          <strong>{{ activeScenario.name }}</strong>
          <div>{{ $t('scenarios.owner') }}: {{ activeScenario.owner }}</div>
        </div>
        <div class="p-text-right">
          <strong>{{ $tmdate.formatScenarioDatePeriod(activeScenario.dateFrom, activeScenario.dateTo) }}</strong>
          <div>{{ $t('scenarios.last edit') }}: {{ $tmdate.formatScenarioDate(activeScenario.updatedAt) }}</div>
        </div>
      </div>
      <span v-else>{{ $t('scenarios.no scenario selected') }}</span>
    </div>
  </header>

  <main>
    <tm-map class="map mobile-map" />
    <div v-if="isMobileView()" class="view-content"><slot /></div>
  </main>

  <footer>
    <div v-touch:press="openFootBar" class="p-component p-d-flex footer-content" @click="openFootBar">
      <tm-button type="link" icon="pi pi-angle-up" />
      <span>{{ $t('navigation.tap to see data selection') }}</span>
    </div>
  </footer>
</template>

<script setup lang="ts">
import PrimeToast from 'primevue/toast';
import PrimeConfirmDialog from 'primevue/confirmdialog';
import PrimeProgressBar from 'primevue/progressbar';
import TmDialog from '@components/layouts/Dialog.vue';
import TmMap from '@components/map/Map.vue';
import PrimeSidebar from 'primevue/sidebar';
import { useStore } from 'vuex';
import { ref, computed, watch } from 'vue';
import TmScenarioList from '@views/scenarios/ScenarioList.vue';
import useFullScenario from '@composables/useFullScenario';
import { useRouter } from 'vue-router';
import TmWidgets from '@components/layouts/Widgets.vue';
import TmNavigation from '@components/layouts/Navigation.vue';
import type { Ref } from 'vue';

const store = useStore();
const router = useRouter();
const { activeScenario, activeScenarioId, activeEventId } = useFullScenario();

const scenarioBarOpened = ref(false);
const footBarOpened = ref(false);
const barLoading: Ref<boolean> = computed(() => store.getters['layout/isLoading']());
const defFootBarHeight = 175;
const footBarHeight = ref(defFootBarHeight);
const footBarPositionY: Ref<number | null> = ref(null);

const dragFootBar = (evt: TouchEvent) => {
  // evt.preventDefault(); // this (or .stop) can not be used since this is passive event..
  const touches = evt.changedTouches;
  const scrY = touches[0]?.clientY;
  if (scrY === undefined) return; // TODO: not available on safari
  const diffY = footBarPositionY.value ? footBarPositionY.value - scrY : 0;
  footBarPositionY.value = scrY;
  footBarHeight.value = footBarHeight.value + diffY;
  if (footBarHeight.value < defFootBarHeight) resetFootBar();
};

const resetFootBar = () => {
  footBarOpened.value = false;
  footBarPositionY.value = null;
  footBarHeight.value = defFootBarHeight;
};

const openFootBar = () => {
  footBarOpened.value = true;
};

const isMobileView = () => {
  const routeName = router.currentRoute.value.name as string;
  return routeName !== 'home' && !routeName.includes('scenarios');
};

watch([activeScenarioId, activeEventId], ([newScId], [oldScId]) => {
  // auto close sidebar after scenario selection
  if (newScId && oldScId !== newScId) {
    scenarioBarOpened.value = false;
    router.push({ name: 'home' }); // go to scenario detail (events) route
  }
  // auto reset footbar after every scenario/event change
  resetFootBar();
});
</script>

<style scoped>
:deep(.map) {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
}
header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 1rem;
  background: white;
}
header :deep(.p-button.tm-link),
footer :deep(.p-button.tm-link) {
  color: #495057;
  margin-right: 1rem;
}
header :deep(.p-button.tm-link .pi) {
  font-size: 1.5rem;
}
header span {
  line-height: 1.5rem;
  color: #495057;
}
.progressbar {
  height: 0.5rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.header-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.7rem;
  line-height: 1rem;
}
.view-content {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
}
.view-content :deep(.p-card) {
  border: none;
  box-shadow: none;
  padding: 0 !important;
}
.view-content :deep(.p-card .content) {
  padding: 0;
  margin: 0;
}
.view-content :deep(.p-card .content),
.view-content :deep(.p-card .footer) {
  padding: 0 2rem;
}
.view-content :deep(.p-card .header) {
  font-weight: normal;
}
.view-content :deep(.p-card .header .p-divider),
.view-content :deep(.p-card .tm-back) {
  display: none;
}
.view-content :deep(.p-card .content) {
  padding: 0 2rem 1rem 2rem;
}
.view-content :deep(.heading) {
  padding-left: 2rem;
  font-size: 1.5rem;
  margin-bottom: 0;
}
footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 1rem;
  background: white;
}
.footer-handle {
  height: 0.3rem;
  width: 3rem;
  margin: auto;
  background: #6c757d;
  border-radius: 6px;
}
.footer-heading {
  margin: 0.5rem auto 1rem auto;
  display: block;
  text-align: center;
  font-size: 1.3rem;
}
</style>

<style>
/* Mobile-only related global styling */
html,
body {
  /* this disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}
body {
  overflow: hidden;
}
/* prime sidebars are outside #app scope and must be styled globally */
.p-sidebar-left {
  width: 80% !important;
}
.p-sidebar-left nav button {
  background-color: #0094d2 !important;
  color: white !important;
  box-shadow: 0 0 0 0.2em #bbdefb !important;
}
.p-sidebar-bottom {
  border-radius: 7px 7px 0 0;
  border-top: 1px solid #ced4da;
  max-height: 100%;
}
.p-sidebar-bottom .heading > span,
.p-sidebar-bottom .widget-title {
  font-weight: normal !important;
  font-size: 1.3rem !important;
  margin: 0 !important;
  flex: 1;
  text-align: center;
}
.p-sidebar-bottom .p-sidebar-content {
  padding: 0 !important;
}
.p-sidebar-bottom .p-sidebar-content > .p-card,
.p-sidebar-bottom .p-sidebar-header {
  border-bottom: 0.5rem solid #dee2e6;
  max-height: none !important;
}
.p-sidebar-bottom .p-sidebar-content > .p-card .content {
  padding: 1rem;
}
.p-sidebar-bottom .header .p-divider {
  display: none;
}
.p-sidebar-header {
  margin-top: 1rem;
  padding: 0 !important;
  border-bottom: 2px solid #dee2e6;
}
.p-sidebar-content .p-card,
.p-sidebar-header .p-card {
  border: none;
  box-shadow: none;
}
.p-sidebar-header .p-sidebar-close {
  display: none;
}
.p-sidebar-header-content,
.p-sidebar-header-content nav,
.p-sidebar-header-content nav .interactive {
  width: 100%;
}
.p-sidebar-content .widget .close-icon {
  display: none;
}
.p-sidebar-content .heading.widget {
  justify-content: center !important;
}
.p-sidebar-header-content .menu {
  display: flex;
}
/* Global styling for OpenLayers base map attribution link (mobile only) */
.mobile-map .tm-ol-attribution {
  bottom: 50%;
  right: 1em;
  transform-origin: top right;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
</style>
