import { getDateRangeIntersectionEvaluator } from '@utils/tm-date';

type ModelSection = { key: string; dateFrom: TmDate; dateTo: TmDate; codeList: string };
type IntersectionSection = { sectionKey: string; extraFeaturesCodeList: string; sectionDateRange: TmDate[] };

export function getScenarioModelSectionsByDate(scenario: TmScenario, calendarDate: TmCalendarDate) {
  const modelSections: ModelSection[] = scenario.modelSections || [];
  let intersectingSections: IntersectionSection[] = [];
  if (isSingleUnlimitedSection(modelSections)) {
    const { key, dateFrom, dateTo, codeList } = modelSections[0];
    intersectingSections = [{ sectionKey: key, extraFeaturesCodeList: codeList, sectionDateRange: [dateFrom, dateTo] }];
  } else {
    intersectingSections = modelSections.reduce((intersectingSections: IntersectionSection[], ms) => {
      if (isCurrentSection(ms, calendarDate))
        intersectingSections.push({
          sectionKey: ms.key,
          extraFeaturesCodeList: ms.codeList,
          sectionDateRange: [ms.dateFrom, ms.dateTo],
        });
      return intersectingSections;
    }, []);
  }
  if (calendarDate?.length == 1 && intersectingSections.length != 1) {
    console.error('BEWARE - MULTIPLE TIME SECTIONS WERE FOUND FOR SINGLE CALENDAR DATE', calendarDate);
  }
  if (!intersectingSections.length) {
    console.info('ONLY DEFAULT MODEL TRAFFIC DATA DISPLAYED');
    intersectingSections = [];
  }
  return intersectingSections;
}

function isSingleUnlimitedSection(sections: ModelSection[]) {
  if (sections.length !== 1) return false;
  const { dateFrom, dateTo } = sections[0];
  return dateFrom === null && dateTo === null;
}

function isCurrentSection(section: ModelSection, calendarDate: TmCalendarDate) {
  const isSectionInsideDateRange = getDateRangeIntersectionEvaluator(calendarDate);
  const { dateFrom, dateTo } = section;
  return isSectionInsideDateRange({ dateFrom, dateTo });

  // const currentDate = toJsDate(date);
  // const dateFrom = toJsDate(section.dateFrom);
  // const dateTo = toJsDate(section.dateTo);
  // if (!currentDate || (!dateFrom && !dateTo)) return false;
  // if (dateFrom && dateTo) return currentDate < dateTo && currentDate >= dateFrom;
  // if (!dateFrom && dateTo) return currentDate < dateTo;
  // if (dateFrom && !dateTo) return currentDate >= dateFrom;
}

/**
 * Cache keys for calculations of 'base model' are not stored as 'model-sections'
 * it is just expected to be in specific format
 * (created by users backend, retrieved directly by client from model API)
 */
export function getBaseModelCacheKey(matrixId?: number) {
  const cachePrefix = import.meta.env.VITE_MODEL_CACHE_PREFIX || 'TM';
  const matrixIdentifier = matrixId ? `-matrix_${matrixId}` : '';
  const baseModelIdentifier = '-default';
  return cachePrefix + matrixIdentifier + baseModelIdentifier;
}
