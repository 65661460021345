<template>
  <div class="tm-slider p-my-3" :class="{ labeled: label }">
    <div class="p-d-flex">
      <span v-if="label" class="p-button" :class="{ 'p-disabled': disabled }"> {{ label }}</span>
      <div class="slider-wrapper">
        <prime-input-number
          v-if="input"
          ref="inputElement"
          v-model="selectedNumber"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="!directInput"
          :suffix="inputSuffix ? ' ' + inputSuffix : undefined"
          :use-grouping="false"
          :min="range.min"
          :max="range.max"
          class="slider-input"
          mode="decimal"
          @mousedown="onInputMouseDown"
        />
        <prime-slider
          v-model="selectedNumber"
          :disabled="disabled"
          :step="step"
          :min="range.min"
          :max="range.max"
          @slideend="$emit('slideend')"
          @click="$emit('click')"
        />
      </div>
    </div>

    <div v-if="!input && selectedNumber !== undefined" class="p-d-flex p-jc-center p-my-3">
      {{ getTimeRange(selectedNumber) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import PrimeSlider from 'primevue/slider';
import PrimeInputNumber from 'primevue/inputnumber';
import useDate from '@composables/useDate';

type SliderValue = number | null;

const props = withDefaults(
  defineProps<{
    selected?: SliderValue; // Pre-selected default selection
    step?: number; // Step factor of the slide
    range?: { min: number; max: number }; // Range of the slider
    label?: string; // Label to display with (before) the slider
    input?: boolean; // Whether to display selected number as input
    disabled?: boolean; // Disabled state of the whole slider (input + slider)
    suffix?: string; // Suffix for the input value (units)
    placeholder?: string; // Text to display as placeholder for the input value
    directInput?: boolean; // Whether the input value can be edited directly
    valueAsTimeRange?: boolean; // Whether to display input value as value range (adding step to the value)
  }>(),
  {
    selected: null,
    step: 1,
    range: () => ({ min: 1, max: 100 }),
    label: undefined,
    input: true,
    disabled: false,
    suffix: undefined,
    placeholder: undefined,
    directInput: false,
    valueAsTimeRange: false,
  },
);

const emit = defineEmits<{
  'update:selected': [value: SliderValue];
  slideend: []; // emits when sliding ends
  click: []; // emits when slider handle is clicked
}>();

const { getHoursFromHoursNumber, getMinutesFromHoursNumber } = useDate();
const inputElement = ref<PrimeInputNumber>();

const selectedNumber = computed({
  get: () => props.selected ?? undefined, // PrimeSlider only expects number/undefined, so we transform null to undefined
  set: (number) => {
    let num: number | null | undefined = number;
    if (Number.isNaN(number)) num = 1; // default number (1) in case PrimeSlider returns NaN (edge cases, only seems to happen when simulating click on the slider via vitest)
    if (num && props.selected === null) num = Math.floor(num); // if the slider had no previous value, sliding can produce weird float numbers, so we prevent it (issue TraMod-954)
    emit('update:selected', num ?? null); // TmSlider expects null in case nothing is selected, so we transform undefined to null
  },
});

const getTimeRange = (timeNumber: number) => {
  const mins = getMinutesFromHoursNumber(timeNumber);
  const hour = getHoursFromHoursNumber(timeNumber);
  const stepMins = getMinutesFromHoursNumber(props.step);
  const stepHour = getHoursFromHoursNumber(props.step) + getHoursFromHoursNumber((mins + stepMins) / 60);
  return `${hour}:${_formatMinutes(mins)} - ${hour + stepHour}:${_formatMinutes(mins + stepMins)} ${props.suffix}`;
};

const inputSuffix = ref(props.suffix);

const _overrideInputFormat = () => {
  // update formatting function of the original prime input-number component, HACKish
  // @ts-ignore
  inputElement.value.formatValue = (v) => getTimeRange(v);
  // PRIMEVUE HACK - by updating the prime-input suffix, we force the format function to be registered
  inputSuffix.value = 'hack';
};

const _formatMinutes = (mins: number) => {
  if (mins == 60) return '00';
  return ('0' + mins).slice(-2);
};

const onInputMouseDown = (ev: MouseEvent) => {
  if (props.directInput) return;
  ev.preventDefault();
};

watch(
  () => props.suffix,
  (suffix) => {
    inputSuffix.value = suffix;
  },
);

onMounted(() => {
  if (!props.input || !props.valueAsTimeRange) return;
  _overrideInputFormat();
});
</script>

<style scoped>
.tm-slider.labeled .p-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tm-slider.labeled :deep(.p-inputtext) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tm-slider .slider-wrapper {
  width: 100%;
}
.tm-slider .slider-input {
  width: 100%;
}
.tm-slider .p-button {
  overflow: visible;
  cursor: default;
}
</style>
