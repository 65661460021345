// LAYER IDS
export const MAP_ID = 'mainMap';
// View layers
export const LID_REAL = 'realTraffic';
export const LID_STA_LINKS = 'modelSTALinks';
export const LID_STA_NODES = 'modelSTANodes';
export const LID_STA_GENERATORS = 'modelSTAGenerators';
export const LID_DTA_LINKS = 'modelDTALinks';
export const LID_DTA_NODES = 'modelDTANodes';
export const LID_DTA_GENERATORS = 'modelDTAGenerators';
export const LID_SIGNS = 'modelSigns';
export const LID_MODIFICATIONS = 'modelModifications';
export const LID_PARKING = 'modelParking';
export const LID_PARKING_ZONES = 'modelParkingZones';

// Model layers groups
export const MODEL_STA_LIDS = {
  link: LID_STA_LINKS,
  node: LID_STA_NODES,
  generator: LID_STA_GENERATORS,
};
export const MODEL_DTA_LIDS = {
  link: LID_DTA_LINKS,
  node: LID_DTA_NODES,
  generator: LID_DTA_GENERATORS,
};

// MAP PRESET KEYS
// viewer modes
export const MP_LIVE_VIEWER = 'live';
export const MP_HISTORICAL_VIEWER = 'historical';
export const MP_MODEL_STA_VIEWER = 'modelSTA';
export const MP_MODEL_DTA_VIEWER = 'modelDTA';
export const MP_MODEL_STA_COMPARISON = 'modelSTA-comparison';
export const MP_MODEL_DTA_COMPARISON = 'modelDTA-comparison';
export const MP_HISTORICAL_COMPARISON = 'historical-comparison';
export const MP_COMBINED_COMPARISON = 'combined-comparison';
export const MP_HISTORICAL_AGGREGATION = 'historical-aggregation';
export const MP_H_AGGREGATION_COMPARISON = 'h_aggregation-comparison';
export const MP_SHORTEST_VIEW = 'model-shortest_path_viewer';
export const MP_MODEL_STA_AGGREGATION = 'modelSTA-aggregation';
export const MP_MODEL_STA_AGGREGATION_COMPARISON = 'modelSTA-aggregation-comparison';
export const MP_MODEL_STA_DATASET = 'modelSTA-dataset';
export const MP_DATASET_MATRIX_COMPARISON = 'dataset-matrix-comparison';
export const MP_MATRICES_COMPARISON = 'matrices-comparison';
// data interaction modes
export const MP_GENERATOR_DRAW = 'model-generator_new_draw';
export const MP_GENERATOR_NEW_NODES = 'model-generator_new_nodes_select';
export const MP_GENERATOR_SELECT = 'model-generator_existing_select';
export const MP_GENERATOR_EXIST_NODES = 'model-generator_existing_nodes_select';
export const MP_LINK_NODES = 'model-link_new_nodes_select';
export const MP_LINK_MODIFY = 'model-link_new_modification';
export const MP_LINK_SELECT = 'model-link_existing_select';
export const MP_NODE_DRAW = 'model-node_new_draw';
export const MP_NODE_SELECT = 'model-node_existing_selection';
export const MP_NODE_LINKS = 'model-node_existing_linking';
export const MP_SHORTEST_SELECT = 'model-shortest_path_select';
export const MP_LINK_EXPORT_SELECT = 'historical-link_existing_select';
export const MP_LINK_EXPORT_SELECT_AGGREGATION = 'comparison-link_existing_select';

// every model interaction mode key in a list (simplifies imports), widget interaction modes are not included
export const INTERACTION_MAP_MODES = [
  MP_GENERATOR_DRAW,
  MP_GENERATOR_NEW_NODES,
  MP_GENERATOR_SELECT,
  MP_GENERATOR_EXIST_NODES,
  MP_LINK_NODES,
  MP_LINK_MODIFY,
  MP_LINK_SELECT,
  MP_NODE_DRAW,
  MP_NODE_SELECT,
  MP_NODE_LINKS,
];

// every comparison map mode in a list
export const COMPARISON_MAP_MODES = [
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
  MP_HISTORICAL_COMPARISON,
  MP_COMBINED_COMPARISON,
  MP_H_AGGREGATION_COMPARISON,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
  MP_DATASET_MATRIX_COMPARISON,
  MP_MATRICES_COMPARISON,
];

// every model map mode in a list
export const MODEL_MAP_MODES = [
  MP_MODEL_STA_VIEWER,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
];

// every aggregation map mode in a list
export const AGGREGATION_MAP_MODES = [
  MP_HISTORICAL_AGGREGATION,
  MP_H_AGGREGATION_COMPARISON,
  MP_MODEL_STA_AGGREGATION,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
];

// helper functions (place elsewhere?)
export const getModelModeByModelType = (modelType: TmModelType) => {
  if (modelType === 'DTA') return MP_MODEL_DTA_VIEWER;
  return MP_MODEL_STA_VIEWER;
};

export const getModelTypeByModelName = (modelName?: string) => {
  // TODO: is it sustainable to retrieve modelType by modelName? (all model names must keep the same prefix)
  // (if not it would be needed to have modelType as query param and also have some default env value)
  if (modelName?.startsWith('dtm_')) return 'DTA';
  return 'STA';
};
