import { get, set, has } from 'lodash-es';
import { ref, readonly } from 'vue';
import { useModelTrafficData } from '@composables/useTrafficData';
import useLayerSourceData from '@composables/useLayerSourceData';
import { LID_STA_LINKS, MP_MODEL_STA_VIEWER } from '@keys/index';
import * as hoursData from './modelHourData.json';

// Local model links typemap of features
// Currently should be static so one time compute should be best performance
const featureTypesCache = new Map();

export default function useSTAHourCalculation(intervalCache) {
  const { sourceData, fetchSourceData, state: sourceState } = useLayerSourceData(LID_STA_LINKS);
  const { fetchTrafficData, getTrafficDataByKey, areTrafficDataAvailable } = useModelTrafficData('STA');

  const isLoading = ref(false);

  const getLinksIntervalCtx = async ({ date, modelCacheKey, scenarioId, extraFeaturesCodeList = {}, filters = {} }) => {
    const dateRange = Array.isArray(date) ? date : [date, date];
    const cacheKey = modelCacheKey + MP_MODEL_STA_VIEWER;
    isLoading.value = cacheKey;
    if (!areTrafficDataAvailable(cacheKey)) await fetchTrafficData({ modelCacheKey, cacheKey });

    const trafficData = getTrafficDataByKey({ cacheKey, scenarioId, codeList: extraFeaturesCodeList.link });
    if (!trafficData) return {};

    if (!sourceState.isLoaded) await fetchSourceData();
    const context = _getModelHourData({
      dateRange,
      cacheKey,
      scenarioId,
      sourceData: sourceData.value,
      trafficData,
      filters,
    });
    if (isLoading.value === cacheKey) isLoading.value = false;

    return context;
  };

  function _getModelHourData({ dateRange, cacheKey, scenarioId, sourceData, trafficData, filters }) {
    const dateKey = new Date(dateRange[0]).getTime() + '_' + new Date(dateRange[1]).getTime();
    const filtersKey = filters?.months?.toString() + '_' + filters?.days?.toString() + '_' + filters?.hours?.toString();
    const intervalKey = `${scenarioId}.${cacheKey}.${dateKey}.${filtersKey}`;
    if (has(intervalCache, intervalKey)) return get(intervalCache, intervalKey);
    const featureTypeMap = _getFeatureTypeMap(sourceData);
    const { coefSumByProfileType, hourCount } = _getCoefSumForDateRange(dateRange, filters);
    const context = {};
    const BASE_HOUR_COEF = 12.4; // used to be 10, than 12.6 (changed in TraMod-952 and TraMod-968)
    for (const [id, baseTrafficVolume] of Object.entries(trafficData)) {
      const baseVolume = baseTrafficVolume || 0; // base volume can be sometimes undefined (extra link with closure - issue TraMod-943)
      const parsedId = isNaN(id) ? id : parseInt(id, 10);
      const featureType = featureTypeMap.get(parsedId) ?? 0;
      const aadt = baseVolume * BASE_HOUR_COEF;
      const hourVolume = Math.floor(aadt * (coefSumByProfileType[featureType] / hourCount));
      const flowSum = Math.floor(aadt * coefSumByProfileType[featureType]);
      context[parsedId] = {
        aadt,
        baseVolume,
        volume: hourVolume,
        flowSum,
        sectionHourSum: hourCount,
      };
      context.sectionHourSum = hourCount; // store hour count under its own key to be more easily accessible
    }
    set(intervalCache, intervalKey, context);
    return context;
  }

  function _getCoefSumForDateRange([dateFrom, dateTo], filters = {}) {
    const coefSumByProfileType = { 0: 0, 1: 0, 2: 0 }; // profileType (0/1/2) from modelHourData, probably useless legacy and only 0 is ever needed, but meh.
    const finalDate = new Date(dateTo);
    let loopDate = new Date(dateFrom);
    let hourCount = 0;
    // TODO: the loops & retrieving coefficients from the json should be optimized!
    while (loopDate <= finalDate) {
      if (!_doesDateFitFilters(loopDate, filters)) {
        loopDate.setHours(loopDate.getHours() + 1);
        continue;
      }

      const coeficientsByType = _getFlowsForDateTime(loopDate);
      Object.keys(coeficientsByType).forEach((coefType) => {
        const coefs = coeficientsByType[coefType];
        const coefForHour = coefs.monthCoef * coefs.dayCoef * coefs.hourCoef;
        coefSumByProfileType[coefType] += coefForHour;
      });
      hourCount++;
      loopDate.setHours(loopDate.getHours() + 1);
    }
    return { coefSumByProfileType, hourCount };
  }

  return {
    getLinksIntervalCtx,
    isLoading: readonly(isLoading),
  };
}

function _doesDateFitFilters(date, filters) {
  if (!filters) return true;
  const fitsMonth = !Array.isArray(filters.months) || filters.months.includes(date.getMonth());
  const fitsDay = !Array.isArray(filters.days) || filters.days.includes(date.getDay());
  const fitsHour = !Array.isArray(filters.hours) || filters.hours.includes(date.getHours());
  return fitsMonth && fitsDay && fitsHour;
}

function _getFeatureTypeMap(sourceData) {
  if (featureTypesCache.size === 0)
    sourceData.features.reduce((list, item) => {
      list.set(item.properties.edge_id, item.properties.type);
      return list;
    }, featureTypesCache);

  return featureTypesCache;
}

// Loads flows coefficient setting for requested date
function _getFlowsForDateTime(dateTime) {
  const flows = {};
  const month = dateTime.getMonth() + 1;
  const monthType = hoursData.conversion.monthType[month];
  const day = dateTime.getDay() === 0 ? 7 : dateTime.getDay();
  const dayType = hoursData.conversion.dayType[day];
  const hour = dateTime.getHours();

  const profileTypes = Object.keys(hoursData.profileType);
  return profileTypes.reduce((acc, type) => {
    acc[type] = {};
    acc[type].monthCoef = hoursData.profileType[type].month[month];
    acc[type].dayCoef = hoursData.profileType[type].monthType[monthType].day[day];
    acc[type].hourCoef = hoursData.profileType[type].monthType[monthType].dayType[dayType][hour];
    return acc;
  }, flows);
}
