import useAxios from '@composables/useAxios';
import { useStore } from 'vuex';
import useToast from '@composables/useToast';

export default function useForeignApiRequest() {
  const store = useStore();
  const { addToast } = useToast();

  async function makeRequest({
    url,
    method,
    params = {},
    message = { error: null, success: null },
    onSuccess = null,
    onFailure = null,
    timeout = 1000 * 30, // 30 seconds
  }: {
    url: string;
    method: 'post' | 'get' | 'patch' | 'delete';
    params?: any;
    message?: { error: null | string; success: null | string };
    onSuccess?: ((arg: any) => void) | null;
    onFailure?: ((arg: any) => void) | null;
    timeout?: number;
  }) {
    const { exec, response, hasError } = useAxios({ isShallow: true });
    store.dispatch('layout/startLoading');
    await exec({ url, method, params, timeout });
    store.dispatch('layout/finishLoading');

    if (!response.value) throw new Error('Encountered unexpected response');

    if (!hasError.value) {
      if (message.success) _showToastMessage({ severity: 'success', summary: message.success });
      if (typeof onSuccess === 'function') await onSuccess(response.value.data);
    } else {
      if (message.error) _showToastMessage({ severity: 'error', summary: message.error });
      if (typeof onFailure === 'function') await onFailure(message.error);
    }
  }

  function _showToastMessage({ severity, summary }: { severity: 'success' | 'error'; summary: string }) {
    addToast({
      severity,
      summary: summary,
    });
  }

  return { makeRequest };
}
