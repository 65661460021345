<template>
  <div class="content">
    <tm-selection v-model:selected="insertType" :options="insertTypeOptions" option-value="type" type="radio" />

    <div class="dataset-wrapper p-mt-3">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <pre v-if="insertType === 'structure'" v-html="datasetStructure"></pre>

      <prime-file-upload
        v-else-if="insertType === 'file'"
        name="file-upload"
        accept="application/json"
        :file-limit="1"
        :max-file-size="1000000"
        :show-upload-button="false"
        :show-cancel-button="false"
        :choose-label="$t('dialogs.choose file')"
        choose-icon="ri-file-search-line"
        :disabled="!!fileData"
        @select="onFileSelect"
        @remove="fileData = null"
      >
        <template #empty>
          <div class="flex align-items-center justify-content-center flex-column">
            <i class="ri-download-fill" />
            <p class="p-mb-0">{{ $t('dialogs.drag and drop file to here') }}</p>
          </div>
        </template>
      </prime-file-upload>

      <prime-data-table v-else show-gridlines :value="measuredFlows" scrollable scroll-height="40vh">
        <prime-column field="edgeId" :header="$t('dialogs.edge id')">
          <template #body="{ data }">
            <prime-input-number v-model="data.edgeId" locale="cs-CZ" />
          </template>
        </prime-column>
        <prime-column field="flow" :header="$t('dialogs.measured value')">
          <template #body="{ data }">
            <prime-input-number v-model="data.flow" locale="cs-CZ" mode="decimal" :max-fraction-digits="10" />
          </template>
        </prime-column>
      </prime-data-table>
    </div>

    <div v-if="insertType === 'file'" class="info-warning">
      <i class="ri-information-line p-mr-1" />
      <p>{{ $t('dialogs.data must meet the required structure') }}</p>
    </div>
  </div>

  <div class="actions p-d-flex p-jc-end">
    <tm-button
      v-if="insertType !== 'structure'"
      :disabled="isInsertDisabled()"
      :label="$t('dialogs.insert')"
      :style="{ width: '30%' }"
      type="confirm"
      @click="onDataInsert"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import useDialog from '@composables/useDialog';
import PrimeFileUpload, { type FileUploadSelectEvent } from 'primevue/fileupload';
import PrimeDataTable from 'primevue/datatable';
import PrimeColumn from 'primevue/column';
import PrimeInputNumber from 'primevue/inputnumber';
import { type TmDataset } from '@composables/useModelDatasets';

type InsertType = 'manual' | 'file' | 'structure';
type Nullable<T> = { [P in keyof T]: T[P] | null };
type TmDatasetInsertableFlows = Nullable<TmDataset['flows'][number]>[];

const { dialog } = useDialog();

const insertType = ref<InsertType>('manual');

const insertTypeOptions: { type: InsertType; label: string }[] = [
  { type: 'manual', label: 'dialogs.manual insert' },
  { type: 'file', label: 'dialogs.file insert' },
  { type: 'structure', label: 'dialogs.dataset structure' },
];

const datasetStructure = JSON.stringify(
  [
    { edge_id: 828, flow: 284 },
    { edge_id: 825, flow: 28 },
  ],
  null,
  2,
);

const fileData = ref<TmDatasetInsertableFlows | null>(null);
const measuredFlows = ref<TmDatasetInsertableFlows>([...(dialog.value?.data?.flows || [])]);

const onDataInsert = () => {
  const insertCallback = dialog.value.callback?.onConfirm;
  if (typeof insertCallback !== 'function') return;
  const datasetData = insertType.value === 'file' ? fileData.value : measuredFlows.value;
  const nonEmptyFlows = datasetData?.filter(
    ({ edgeId, flow }) => typeof edgeId === 'number' && typeof flow === 'number',
  );
  insertCallback({ flows: nonEmptyFlows || [] });
};

const onFileSelect = (ev: FileUploadSelectEvent) => {
  const file = ev.files[0];
  if (!file) return;
  const reader = new FileReader();
  reader.onload = (e) => {
    const content = e.target?.result as string;
    try {
      fileData.value = parseFileData(JSON.parse(content));
    } catch (error) {
      console.error('Error while parsing JSON file', error);
    }
  };
  reader.readAsText(file);
};

const isInsertDisabled = () => {
  const fileInsertDisabled = insertType.value === 'file' && !fileData.value;
  const manualInsertDisabled = insertType.value === 'manual' && measuredFlows.value.length < 2;
  return fileInsertDisabled || manualInsertDisabled;
};

const parseFileData = (data: unknown): TmDatasetInsertableFlows => {
  if (!isDatasetFile(data)) return [];
  return data.map((o) => ({
    edgeId: 'edge_id' in o && typeof o.edge_id === 'number' ? o.edge_id : null,
    flow: 'flow' in o && typeof o.flow === 'number' ? o.flow : null,
  }));
};

const isDatasetFile = (data: unknown): data is object[] =>
  Array.isArray(data) && data.every((item): item is object => typeof item === 'object' && item !== null);

watch(insertType, (type) => {
  if (type === 'manual' && fileData.value) measuredFlows.value = fileData.value;
  if (type !== 'file') fileData.value = null;
});

watch(
  measuredFlows,
  (flows) => {
    const lastRowData = flows.at(-1);
    if (lastRowData?.edgeId === null) return;
    // add new empty table row so user can input additional data
    measuredFlows.value.push({ edgeId: null, flow: null });
  },
  { deep: true, immediate: true },
);
</script>

<style scoped>
.dataset-wrapper {
  min-width: 60vw;
  border: solid 1px #ced4da;
}
.dataset-wrapper pre {
  padding-left: 10%;
  color: #00000061;
}
.p-fileupload {
  display: flex;
  flex-direction: column-reverse;
}
.p-fileupload :deep(.p-fileupload-buttonbar) {
  display: flex;
  justify-content: center;
  background: inherit;
  border: none;
}
.p-fileupload :deep(.p-fileupload-content) {
  border: none;
  text-align: center;
  padding-bottom: 0;
}
.p-fileupload :deep(.p-fileupload-content i) {
  font-size: 5rem;
}
.p-fileupload :deep(.p-fileupload-file-thumbnail),
.p-fileupload :deep(.p-progressbar) {
  display: none;
}
.info-warning {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #0094d2;
}
.info-warning i {
  font-size: 1rem !important;
}
.p-datatable :deep(.p-datatable-tbody > tr > td) {
  padding: 0;
}
.p-datatable :deep(.p-inputnumber) {
  width: 100%;
}
.p-datatable :deep(.p-inputnumber input) {
  border: none;
  outline: none;
  box-shadow: none;
}
</style>
