<template>
  <aside>
    <!-- alert messages -->
    <prime-toast position="bottom-center" />

    <!-- confirmation dialog -->
    <prime-confirm-dialog />

    <!-- custom tm dialog -->
    <tm-dialog :dismissable="false" />
  </aside>

  <main>
    <tm-card>
      <template v-if="isUserLoggedAs('userManager') || isUserLoggedAs('textManager')" #header>
        <div class="p-d-flex">
          <tm-heading :text="$t('admin.admin section')" />
          <a
            v-tooltip.left="$t('admin.go to app')"
            class="p-ml-auto action-icon"
            @click="$router.push({ name: 'home' })"
          >
            <i class="pi pi-home" />
          </a>
          <a v-tooltip.left="$t('admin.log out')" class="p-ml-3 action-icon" @click="logout">
            <i class="pi pi-sign-out" />
          </a>
        </div>
        <tm-divider />

        <prime-menubar :model="menuItems" class="p-mb-3">
          <template #end>
            <tm-locale />
          </template>
        </prime-menubar>
      </template>
      <template #content>
        <slot />
      </template>
    </tm-card>
  </main>
</template>

<script setup lang="ts">
import PrimeToast from 'primevue/toast';
import PrimeConfirmDialog from 'primevue/confirmdialog';
import TmDialog from '@components/layouts/Dialog.vue';
import useAuth from '@composables/useAuth';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import PrimeMenubar from 'primevue/menubar';
import TmLocale from '@components/layouts/Locale.vue';
import type { MenuItem } from 'primevue/menuitem';

const { logout, isUserLoggedAs } = useAuth();
const route = useRoute();
const { t } = useI18n();

const getItemClass = (routeName: string) => (route?.name === routeName ? 'active' : '');

const menuItems = computed<MenuItem[]>(() => {
  const accessibleMenuItems = [];

  if (isUserLoggedAs('userManager'))
    accessibleMenuItems.push({
      label: t('admin.user management'),
      class: getItemClass('admin.users'),
      to: { name: 'admin.users' },
    });

  if (isUserLoggedAs('textManager'))
    accessibleMenuItems.push({
      label: t('admin.application texts'),
      class: getItemClass('admin.texts'),
      items: [
        { label: t('admin.about text'), to: { name: 'admin.texts', params: { text: 'about' } } },
        { label: t('admin.help text'), to: { name: 'admin.texts', params: { text: 'help' } } },
        { label: t('admin.changelog text'), to: { name: 'admin.texts', params: { text: 'changelog' } } },
      ],
    });

  return accessibleMenuItems;
});
</script>

<style scoped>
.p-card {
  height: 100vh;
}
.p-card :deep(.p-card:first-of-type) {
  padding: 0 !important;
}
.action-icon {
  cursor: pointer;
  font-size: 1.8rem;
}
.action-icon:hover i {
  font-weight: bold;
}
:deep(.active) {
  background: #0094d2 !important;
}
:deep(.p-menubar-root-list > .p-menuitem) {
  margin-right: 1rem;
}
:deep(.p-menubar-root-list > .p-menuitem),
:deep(.p-menubar-root-list > .p-menuitem > .p-menuitem-content) {
  border-radius: 3px !important;
}
:deep(.p-menubar-root-list > .active > .p-menuitem-content > .p-menuitem-link > span) {
  color: white !important;
}
:deep(.p-menubar-root-list > .p-menuitem > .p-menuitem-content:hover) {
  background: #2196f31f !important;
}
:deep(.p-menubar-root-list > .p-menuitem > .p-menuitem-content:hover span) {
  color: #0094d2 !important;
}
:deep(.p-menubar-root-list > .active > .p-menuitem-content:hover) {
  color: initial !important;
  background: #0094d2 !important;
}
:deep(.p-menubar-root-list > .active > .p-menuitem-content:hover span) {
  color: white !important;
}
:deep(.p-menubar-root-list > .p-focus > .p-menuitem-content),
:deep(.p-menuitem.p-highlight > .p-menuitem-content) {
  background: initial !important;
}
</style>
