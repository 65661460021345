/*
  StyleService is used as mapMode config defaults storage, new config setter and caller of styleConfig update listeners
  After recent modularization of layer-style, this could be probably rewritten to composable function, so it can access VuexStore directly with Map.vue glue
  Also config default could be provided by mapModes for better separation of concerns, but imho this list is quite small, so it's not that important
*/

import {
  MP_MODEL_STA_VIEWER,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
  MP_HISTORICAL_COMPARISON,
  MP_COMBINED_COMPARISON,
  MP_H_AGGREGATION_COMPARISON,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
  MP_MODEL_STA_DATASET,
  MP_DATASET_MATRIX_COMPARISON,
  MP_MATRICES_COMPARISON,
} from '@keys/index';

import { NO_MODE } from './styleConfig';

const styleMapModeSets = {
  general: {
    vehiclesCountDisplayed: false,
    trafficSignsStyle: false,
    modelLinkOffset: false,
    highlightedLinks: [], // list of link IDs to be highlighted - issue TraMod-713
    hideVirtualEdges: false, // hide edges with the 'isvirtual' prop - issue TraMod-1001
    customPalette: null, // custom colors and levels palette representing link intensity - issue TraMod-1113
  },
  [NO_MODE]: {
    width: 'volume',
    color: 'intensity',
    text: 'volume',
  },
  [MP_MODEL_STA_VIEWER]: {
    width: 'volume',
    color: 'intensity',
    text: 'volume',
  },
  [MP_MODEL_DTA_VIEWER]: {
    output: 'outflow-capacity',
    width: 'outFlow',
    color: 'outFlowIntensity',
    text: 'outFlow',
  },
  [MP_MODEL_STA_COMPARISON]: {
    width: 'volumeDiff',
    color: 'volumeDiff',
    text: 'volumeDiff',
  },
  [MP_MODEL_DTA_COMPARISON]: {
    width: 'outFlowDiff',
    color: 'outFlowDiff',
    text: 'outFlowDiff',
  },
  [MP_HISTORICAL_COMPARISON]: {
    width: 'volume',
    color: 'volume',
    text: 'volume',
  },
  [MP_H_AGGREGATION_COMPARISON]: {
    width: 'volume',
    color: 'volume',
    text: 'volume',
  },
  [MP_COMBINED_COMPARISON]: {
    width: 'volumeDiff',
    color: 'volumePercentChange',
    text: 'volumePercentChange',
  },
  [MP_MODEL_STA_AGGREGATION_COMPARISON]: {
    width: 'volumeDiff',
    color: 'volumeDiff',
    text: 'volumeDiff',
  },
  [MP_MODEL_STA_DATASET]: {
    width: 'volume',
    color: 'intensity',
    text: 'volume',
  },
  [MP_DATASET_MATRIX_COMPARISON]: {
    width: 'volume',
    color: 'volume',
    text: 'volume',
  },
  [MP_MATRICES_COMPARISON]: {
    width: 'volume',
    color: 'volume',
    text: 'volume',
  },
};

export const commonStyleConfig = styleMapModeSets.general;

const styleConfigUpdateListeners = {};
const callOnUpdate = (mapMode) => styleConfigUpdateListeners[mapMode]?.(mapMode, getStyleConfigByMode(mapMode));

export const addOnStyleConfigUpdate = (mapMode, onUpdateFn, immediate = true) => {
  if (styleConfigUpdateListeners[mapMode]) {
    console.warn(`Style update listener for ${mapMode} already added`);
    return;
  }
  styleConfigUpdateListeners[mapMode] = onUpdateFn;
  if (immediate) callOnUpdate(mapMode);
};

export const setStyleConfig = (updatedConfig) => {
  const updatedModes = Object.keys(updatedConfig);
  for (const mapMode of updatedModes) {
    const oldVal = JSON.stringify(styleMapModeSets[mapMode]);
    Object.assign(styleMapModeSets[mapMode], updatedConfig[mapMode]);
    if (mapMode !== 'general' && oldVal !== JSON.stringify(styleMapModeSets[mapMode])) callOnUpdate(mapMode);
  }
};

export const getStyleConfigByMode = (mapMode) => {
  const usedConfig = styleMapModeSets[mapMode] || styleMapModeSets[NO_MODE];
  if (styleMapModeSets.general.vehiclesCountDisplayed && !usedConfig.output) return usedConfig;
  const configCopy = { ...usedConfig };
  delete configCopy.text;
  delete configCopy.output;
  return configCopy;
};
