import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import useMainApiRequest from '@composables/useMainApiRequest';
import useDialog from '@composables/useDialog';
import type { ButtonColor } from '@components/core/Button.vue';

export default function useModifications(
  itemIds: TmItemIds,
  {
    cacheModification,
    invalidateModification,
    invalidateNodeTransit,
  }: {
    cacheModification: (
      mod: TmModification | TmModification[],
      ids?: { scenarioId: number; eventId: number; modificationId?: number },
    ) => void;
    invalidateModification: (ids: { scenarioId: number; eventId?: number; modificationId?: number }) => void;
    invalidateNodeTransit: (scId?: number, nodeId?: string) => void;
  },
) {
  const store = useStore();
  const { t } = useI18n();
  const router = useRouter();
  const { makeRequest, checkIds } = useMainApiRequest();
  const dialog = useDialog();
  const isDta = computed<boolean>(() => store.getters['scenarios/isDtaModelTypeActive']);
  const shouldLoadWithChanges = (id: number): boolean => store.getters['scenarios/isUsingChangedScenario'](id);

  async function fetchModifications() {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, itemIds.eventId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/modifications`,
      method: 'get',
      ...(shouldLoadWithChanges(scenarioId) ? { params: { useChanged: true } } : {}),
      message: {
        error: {
          404: { summary: t('modifications.no modifications found'), severity: 'info' },
          default: t('modifications.error while fetching modifications'),
        },
      },
      onSuccess: (result: { modifications: TmModification[] }) => {
        cacheModification(result.modifications, { scenarioId, eventId });
      },
    });
  }

  async function fetchModification(id?: number) {
    const [scenarioId, eventId, modificationId] = checkIds([itemIds.scenarioId, itemIds.eventId, id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/modifications/${modificationId}`,
      method: 'get',
      ...(shouldLoadWithChanges(scenarioId) ? { params: { useChanged: true } } : {}),
      message: {
        error: t('modifications.error while fetching modification'),
      },
      onSuccess: (result: { modification: TmModification }) => {
        cacheModification(result.modification, { scenarioId, eventId, modificationId });
      },
    });
  }

  async function createModification(modification: TmModification, { leaveAfter = false } = {}) {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, itemIds.eventId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/modifications`,
      method: 'post',
      data: {
        type: modification.type,
        mode: modification.mode,
        name: modification.name,
        description: modification.description,
        note: modification.note,
        dateFrom: modification.dateFrom,
        dateTo: modification.dateTo,
        ..._getModificationDataByTypeAndMode(modification, { isDta: isDta.value }),
      },
      message: {
        success: t('modifications.modification created'),
        error: t('modifications.failed to create modification'),
      },
      onSuccess: async (result: { modification: TmModification; editSession: TmEditSession }) => {
        const resultedModification = result.modification;
        cacheModification(resultedModification, {
          scenarioId,
          eventId,
          modificationId: resultedModification.id,
        });
        _invalidateNodeTransit(resultedModification);

        if (leaveAfter) router.push({ name: 'scenarios.events.modifications' });
      },
    });
  }

  async function updateModification(modification: TmModification, { leaveAfter = false } = {}) {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, itemIds.eventId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/modifications/${modification.id}`,
      method: 'patch',
      data: {
        type: modification.type, // this seems to be required for server side validation
        mode: modification.mode, // this seems to be required for server side validation
        name: modification.name,
        description: modification.description,
        note: modification.note,
        dateFrom: modification.dateFrom,
        dateTo: modification.dateTo,
        ..._getModificationDataByTypeAndMode(modification, { isDta: isDta.value }),
      },
      message: {
        success: t('modifications.modification updated'),
        error: t('modifications.failed to update modification'),
      },
      onSuccess: (result: { modification: TmModification; editSession: TmEditSession }) => {
        const resultedModification = result.modification;
        cacheModification(resultedModification, {
          scenarioId,
          eventId,
          modificationId: resultedModification.id,
        });
        _invalidateNodeTransit(resultedModification);

        if (leaveAfter) router.push({ name: 'scenarios.events.modifications' });
      },
    });
  }

  async function deleteModification(id?: number, { deleteDependencies = false } = {}) {
    const [scenarioId, eventId, modificationId] = checkIds([itemIds.scenarioId, itemIds.eventId, id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/modifications/${modificationId}`,
      method: 'delete',
      ...(deleteDependencies && { params: { deleteDependencies: true } }),
      message: {
        success: t('modifications.modification deleted'),
        error: { default: t('modifications.failed to delete modification'), DEPENDENCIES_ERROR: null },
      },
      onSuccess: (result: {
        modification: TmModification;
        editSession: TmEditSession;
        deletedModifications: { evId: number; modId: number; mode: TmModificationMode; type: TmModificationType }[];
      }) => {
        // invalidate each modification & update related resources (node transit and extra features)
        result.deletedModifications.forEach(({ evId, modId, mode, type }) => {
          invalidateModification({ scenarioId, eventId: evId, modificationId: modId });
          _invalidateNodeTransit({ mode, type });
        });

        const activeModId: number | null = store.getters['map/getActiveItemId']({ itemLevel: 'modification' });
        if (modificationId == activeModId) store.dispatch('map/activateItem', { scenarioId, eventId });
      },
      onFailure: (error: TmApiResponse['data']) => {
        if (error.code === 'DEPENDENCIES_ERROR') {
          dialog.show({
            type: 'dependencies',
            data: { dependencies: error.message?.split('modifications:')[1] },
            callback: {
              onConfirm: async () => {
                await deleteModification(modificationId, { deleteDependencies: true });
                dialog.close();
              },
            },
          });
        }
      },
    });
  }

  async function copyModification(id?: number) {
    const [scenarioId, eventId, modificationId] = checkIds([itemIds.scenarioId, itemIds.eventId, id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/modifications/${modificationId}/copy`,
      method: 'post',
      message: {
        success: t('modifications.modification copied'),
        error: t('modifications.failed to copy modification'),
      },
      onSuccess: (result: { modification: TmModification; editSession: TmEditSession }) => {
        const resultedModification = result.modification;
        cacheModification(resultedModification, {
          scenarioId,
          eventId,
          modificationId: resultedModification.id,
        });
        _invalidateNodeTransit(resultedModification);
        // activate copied item
        store.dispatch('map/activateItem', {
          scenarioId,
          eventId,
          modificationId: resultedModification.id,
          date: resultedModification.dateFrom,
        });
      },
    });
  }

  function _invalidateNodeTransit({ mode, type }: { mode?: TmModificationMode; type?: TmModificationType }) {
    if (mode === 'existing' && type === 'node') invalidateNodeTransit(itemIds.scenarioId);
  }

  return {
    fetchModifications,
    fetchModification,
    createModification,
    updateModification,
    deleteModification,
    copyModification,
    types,
  };
}

export const types: {
  type: TmModificationType;
  mode: TmModificationMode;
  icon: string;
  border?: ButtonColor;
}[] = [
  { type: 'link', mode: 'new', icon: 'ri-git-branch-fill' },
  { type: 'link', mode: 'existing', icon: 'ri-git-branch-fill', border: 'yellow' },
  { type: 'node', mode: 'new', icon: 'ri-git-commit-fill' },
  { type: 'node', mode: 'existing', icon: 'ri-git-commit-fill', border: 'yellow' },
  { type: 'generator', mode: 'new', icon: 'ri-car-line' },
  { type: 'generator', mode: 'existing', icon: 'ri-car-line', border: 'yellow' },
  { type: 'matrix', mode: 'existing', icon: 'ri-grid-fill', border: 'yellow' },
];

function _getModificationDataByTypeAndMode(modification: TmModification, { isDta = false } = {}) {
  const { type, mode } = modification;
  const typeMode = `${type}-${mode}`;

  switch (typeMode) {
    case 'link-existing':
      return {
        linkId: modification.linkId,
        speed: modification.speed,
        capacity: modification.capacity,
        hasRelativeData: modification.hasRelativeData,
        ...(isDta && { lanes: modification.lanes }),
      };
    case 'link-new':
      return {
        speed: modification.speed,
        capacity: modification.capacity,
        coordinates: modification.coordinates,
        source: modification.source,
        target: modification.target,
        twoWay: modification.twoWay,
        twoWaySpeed: modification.twoWaySpeed,
        twoWayCapacity: modification.twoWayCapacity,
        ...(isDta && { lanes: modification.lanes, twoWayLanes: modification.twoWayLanes }),
      };
    case 'node-existing':
      return {
        node: modification.node,
        linkFrom: modification.linkFrom,
        linkTo: modification.linkTo,
        ...(isDta ? { capacity: modification.capacity } : { cost: modification.cost }),
      };
    case 'node-new':
      return {
        node: modification.node,
      };
    case 'generator-existing':
      return {
        generator: modification.generator,
        nodes: modification.nodes,
        inTraffic: modification.inTraffic,
        outTraffic: modification.outTraffic,
      };
    case 'generator-new':
      return {
        generator: modification.generator,
        nodes: modification.nodes,
        inTraffic: modification.inTraffic,
        outTraffic: modification.outTraffic,
      };
    default:
      return {};
  }
}
