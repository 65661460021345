<template>
  <nav class="p-d-flex p-jc-between">
    <div v-if="showRoutes" class="interactive p-card" data-test="route-links">
      <template v-for="(route, index) in enabledRouteLinks" :key="index">
        <tm-button
          :data-test="`route-link-${route.name}`"
          :tooltip="$t('navigation.' + route.tooltip)"
          type="navigation"
          class="p-mx-2"
          :class="{ active: isInActiveRouteTree(route.name) }"
          :icon="route.icon"
          @click="changeRoute(route.name)"
        />
        <tm-scenarios-badge v-if="route.name == 'scenarios'" />
      </template>
    </div>

    <tm-controls v-if="showMapControls" :controls="['zoom']" class="interactive p-ai-center" />

    <div v-if="showDataModes" class="interactive p-card" :class="{ hovered: showWidgets }">
      <div v-if="showWidgets && !isWidgetPanelClosed" class="widgets" data-test="widgets">
        <template v-for="{ name, icon, buttonComponent } in widgets" :key="name">
          <component :is="buttonComponent" v-if="buttonComponent && !$isMobile()" :icon="icon"></component>
          <tm-button
            v-else
            :data-test="`widget-${name}`"
            type="widget"
            :icon="icon"
            :class="{ active: isWidgetActive(name) }"
            :tooltip="$t(`widgets.${name} widget`)"
            :disabled="isWidgetDisabled(name)"
            @click="toggleWidget(name)"
          />
        </template>
        <tm-divider layout="vertical" />
      </div>
      <div class="menu" data-test="data-modes">
        <template v-for="mode in navDataModes" :key="mode.name">
          <tm-button
            v-if="mode.isBroken"
            :data-test="`broken-mode-${mode.name}`"
            type="navigation"
            icon="pi-refresh"
            class="tm-reload p-mx-2"
            @click="refreshApp()"
          />
          <tm-button
            v-else
            :data-test="`data-mode-${mode.name}`"
            :tooltip="$t('navigation.' + mode.tooltip)"
            type="navigation"
            class="p-mx-2 map-mode-button"
            :class="{ active: isActiveDataMode(mode) }"
            :disabled="!$isMobile() && isNavDisabled()"
            @click="!isNavDisabled() && changeDataMode(mode)"
          >
            <span v-if="isWidgetPanelClosed && !$isMobile()" class="hover-only"><i class="pi pi-plus" /></span>
            <span v-if="!isWidgetPanelClosed && !$isMobile()" class="hover-only"><i class="pi pi-minus" /></span>
            <span class="mode-icon" :class="[mode.name, { mobile: $isMobile() }]"><i :class="mode.icon" /></span>
          </tm-button>
        </template>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import TmControls from '@components/layouts/Controls.vue';
import TmScenariosBadge from '@components/layouts/ScenariosBadge.vue';
import useWidgets from '@composables/useWidgets';
import useMapModes from '@composables/useMapModes';
import useDataModes from '@composables/useDataModes';
import useModels from '@composables/useModels';
import useMobile from '@composables/useMobile';
import { INTERACTION_MAP_MODES } from '@keys/index';
import type { TmDataModeObj } from '@composables/useDataModes';

type TmRouteLink = { name: string; icon: string; tooltip: string };

const props = withDefaults(
  defineProps<{
    routeLinks?: TmRouteLink[]; // List of desired route navigation options
    dataModes?: TmDataModeObj[]; //  List of desired data mode navigation options
    showRoutes?: boolean; //  Whether to show route navigation
    showDataModes?: boolean; // Whether to show data mode navigation
    showMapControls?: boolean; // Whether to show map controls navigation
    showWidgets?: boolean; // Whether to show widget icons, also displays non-active map modes on hover only
  }>(),
  {
    routeLinks: () => [
      { name: 'user', icon: 'ri-user-line', tooltip: 'user' },
      { name: 'scenarios', icon: 'ri-file-list-line', tooltip: 'scenarios' },
      { name: 'models', icon: 'ri-flow-chart', tooltip: 'models' },
      { name: 'settings', icon: 'ri-settings-3-line', tooltip: 'settings' },
    ],
    dataModes: undefined,
    showRoutes: true,
    showDataModes: true,
    showMapControls: true,
    showWidgets: true,
  },
);

const emit = defineEmits<{
  navigated: []; //  navigated event - triggers on navigation change
}>();

const store = useStore();
const router = useRouter();
const { currentMapMode } = useMapModes();
const { availableDataModes, isActiveDataMode, setDataMode } = useDataModes(props.dataModes);
const { fetchModels } = useModels();
const isMobile = useMobile();
const {
  enabledWidgets: widgets,
  isWidgetDisabled,
  isWidgetActive,
  openWidgetsForDataMode,
  toggleWidget,
} = useWidgets();

const isWidgetPanelClosed = computed(() => store.state.layout.panels.right === 'closed');
const currentRouteName = computed(() => router.currentRoute.value.name as string);

const navDataModes = computed(() => {
  const dataModes = [...availableDataModes.value];
  const isFirefox = navigator?.userAgent?.toLowerCase().indexOf('firefox') > -1;
  if (isFirefox) dataModes.reverse(); // re-order for firefox
  // reorder map modes so the active one is always the first
  if (props.showWidgets) dataModes.sort((m) => (isActiveDataMode(m) ? -1 : 1));
  return dataModes;
});

const enabledRouteLinks = computed(() => {
  const enabledRoutes = import.meta.env.VITE_ENABLED_ROUTE_LINKS?.split(', ') || [];
  return props.routeLinks.filter(({ name }) => {
    // only show nav button if it is enabled
    return enabledRoutes.includes(name);
  });
});

const changeDataMode = (mode: TmDataModeObj) => {
  setDataMode(mode);
  emit('navigated');
  // in case data mode is being switched to 'live' or 'historical' and scenarios are opened (left panel) -> also navigate home; issue TraMod-965 & TraMod-1237
  if (!['live', 'historical'].includes(mode.name)) return;
  if (!currentRouteName.value.startsWith('scenarios') && !currentRouteName.value.startsWith('models.datasets')) return;
  router.push({ name: 'home' });
};

const changeRoute = (routeName: string) => {
  const nextRoute = currentRouteName.value === routeName ? 'home' : routeName;
  router.push({ name: nextRoute });
  emit('navigated');
};

const isInActiveRouteTree = (routeName: string) => currentRouteName.value?.split('.')[0] == routeName.split('.')[0];

const isNavDisabled = () => INTERACTION_MAP_MODES.includes(currentMapMode.value);

const refreshApp = () => store.dispatch('refreshApp');

const preOpenAllWidgets = () => {
  availableDataModes.value.forEach((dm) => openWidgetsForDataMode(dm.name));
};

onBeforeMount(async () => {
  if (isMobile()) preOpenAllWidgets();
  await fetchModels(); // ensure that models are fetched and ready
});
</script>

<style scoped>
:deep(.controls > div) {
  box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
}
.interactive {
  height: fit-content;
  min-height: 4rem;
  display: flex;
  justify-content: space-evenly;
  padding: 0.9rem 0.8rem;
}
.p-card.hovered {
  position: relative;
  padding-right: 3.5rem;
}
.p-card.hovered .menu {
  position: absolute;
  background-color: white;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
  padding-top: 0.8rem;
  display: flex;
  flex-direction: column;
  max-height: 4rem;
  overflow: hidden;
  border-radius: 3px;
  -webkit-transition: max-height 0.5s linear;
  -moz-transition: max-height 0.5s linear;
  -o-transition: max-height 0.5s linear;
  transition: max-height 0.5s linear;
}
.p-card.hovered .menu:hover {
  max-height: 100vh;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px -1px rgb(0 0 0 / 12%);
}
.p-card.hovered .menu .tm-button {
  margin: 0 !important;
  padding: 1rem;
  padding-top: 0;
}
.widgets {
  display: flex;
}
.map-mode-button button span.hover-only,
.map-mode-button.active button:hover span:not(.hover-only) {
  display: none;
}
.map-mode-button.active button:hover span.hover-only,
.map-mode-button.active button:hover span.mobile {
  display: block;
}
.mode-icon {
  padding: 0.3rem 0 0 0;
}
.mode-icon.model {
  padding: 0.3rem 0 0 0.1rem;
}
.mode-icon.live {
  padding: 0.15rem 0 0 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mode-icon.historical {
  padding: 0.2rem 0 0 0.1rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
:deep(.p-badge) {
  background: #f05a28;
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  vertical-align: 10%;
  line-height: 1rem;
  margin-left: -1rem;
  z-index: 1;
}
</style>
