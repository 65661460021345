<template>
  <prime-dialog
    ref="dialogRef"
    v-model:visible="displayed"
    :modal="options.modal"
    :dismissable-mask="options.dismissable"
    :header="$t(options.header)"
    :content-class="'dialog-' + dialog.type"
    :show-header="options.showHeader"
    :maximizable="options.showMaximized"
    class="tm-dialog"
  >
    <component :is="options.component" v-if="options.component" />
    <div v-else>
      <div class="content">
        <i class="p-mr-3 pi pi-exclamation-triangle" />
        <span v-if="options.message">{{ $t(options.message) }}</span>
      </div>
      <div class="actions">
        <tm-button :label="$t('dialogs.close')" type="cancel" @click="closeDialog()" />
      </div>
    </div>
  </prime-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import useDialog from '@composables/useDialog';
import PrimeDialog from 'primevue/dialog';
import TmDialogTimeline from '@components/layouts/dialogs/DialogTimeline.vue';
import TmDialogDependencies from '@components/layouts/dialogs/DialogDependencies.vue';
import TmDialogUnfinished from '@components/layouts/dialogs/DialogUnfinished.vue';
import TmDialogUser from '@components/layouts/dialogs/DialogUser.vue';
import TmDialogExports from '@components/layouts/dialogs/DialogExports.vue';
import TmDialogImageSelection from '@components/layouts/dialogs/DialogImageSelection.vue';
import TmDialogDataset from '@components/layouts/dialogs/DialogDataset.vue';

const props = withDefaults(
  defineProps<{
    modal?: boolean; // Whether the dialog should disable background
    dismissable?: boolean; // Whether the dialog should be dismissable on background click
  }>(),
  {
    modal: true,
    dismissable: false,
  },
);

const { displayed, dialog, close: closeDialog } = useDialog();
const dialogRef = ref();

const componentsByType = {
  timeline: TmDialogTimeline,
  dependencies: TmDialogDependencies,
  unfinished: TmDialogUnfinished,
  user: TmDialogUser,
  exports: TmDialogExports,
  images: TmDialogImageSelection,
  dataset: TmDialogDataset,
};

const options = computed(() => ({
  header: dialog.value.header || `dialogs.${dialog.value.type || 'alert'}`,
  message: dialog.value.message,
  component: dialog.value.type ? componentsByType[dialog.value.type] : null,
  modal: dialog.value.modal ?? props.modal,
  dismissable: dialog.value.dismissable ?? props.dismissable,
  showHeader: dialog.value.showHeader ?? true,
  showMaximized: dialog.value.showMaximized ?? false,
}));

watch(dialog, ({ showMaximized }) => {
  if (showMaximized) dialogRef.value.maximized = true;
  else dialogRef.value.maximized = false;
});
</script>
