<template>
  <form>
    <span class="block-heading">{{ $t('users.general settings') }}</span>
    <tm-input
      v-if="isCreating || !isAdminEdit"
      v-model="user.username"
      :placeholder="$t('users.username')"
      type="text"
      name="username"
      rules="required|min:4"
      :borderless="false"
      class="p-mb-3"
    />

    <div v-else class="p-d-flex p-jc-between">
      <span>{{ $t('users.username') }}</span>
      <span data-test="username"
        ><strong>{{ user.username }}</strong></span
      >
    </div>

    <tm-input
      v-if="isCreating || !isAdminEdit"
      v-model="user.email"
      :placeholder="$t('users.email')"
      type="email"
      name="email"
      rules="required|email"
      :borderless="false"
      class="p-mb-3"
    />

    <div v-else class="p-d-flex p-jc-between">
      <span>{{ $t('users.email') }}</span>
      <span data-test="email"
        ><strong>{{ user.email }}</strong></span
      >
    </div>

    <tm-input
      v-if="!isAdminEdit"
      v-model="user.oldPassword"
      :placeholder="$t('users.old password')"
      type="password"
      name="old_password"
      rules="min:8"
      :borderless="false"
      class="p-mb-3"
    />

    <tm-divider />
    <span class="block-heading">{{ $t('users.organization') }}</span>
    <tm-selection
      v-if="isAdminEdit"
      v-model:selected="user.organization"
      :options="filteredOrganizations"
      type="autocomplete-dropdown"
      :placeholder="$t('users.select organization')"
      :forced-selection="false"
      class="organizations-field"
      @autocomplete="searchForOrganization"
    />
    <span v-else data-test="organization">{{ user.organization }}</span>

    <tm-divider />
    <span class="block-heading">{{ $t('users.roles') }}</span>
    <tm-selection
      v-if="isAdminEdit"
      v-model:selected="selectedUserRoles"
      :options="userRolesOptions"
      class="p-d-flex p-flex-column"
    />
    <span v-for="(role, index) in user.roles" v-else :key="role" data-test="roles">
      {{ getRoleName(role) }}
      <span v-if="user.roles && index < user.roles?.length - 1">, </span>
    </span>

    <template v-if="!isCreating">
      <tm-divider />
      <span class="block-heading">{{ $t('users.password change') }}</span>

      <template v-if="!isAdminEdit">
        <tm-input
          v-model="user.newPassword"
          :placeholder="$t('users.new password')"
          type="password"
          name="new_password"
          autocomplete="new-password"
          rules="min:8"
          :borderless="false"
          class="p-mb-3"
        />
        <tm-input
          v-model="passwordConfirmation"
          :placeholder="$t('users.control password')"
          type="password"
          name="confirm_password"
          autocomplete="new-password"
          rules="confirmed:new_password"
          :borderless="false"
          class="p-mb-3"
        />
        <tm-input
          v-model="user.oldPassword"
          :placeholder="$t('users.old password')"
          type="password"
          name="old_password"
          rules="min:8"
          :borderless="false"
          class="p-mb-3"
        />
        <tm-divider />
      </template>

      <div class="p-d-flex p-ai-center p-my-2">
        <tm-button type="link" :label="$t('users.request password reset')" @click="sendPasswordRequest" />
      </div>
    </template>

    <tm-divider />
    <tm-button
      type="confirm"
      class="p-mt-2"
      :label="isCreating ? $t('users.create and send e-mail') : $t('users.save')"
      @click="saveUser"
    />
  </form>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useForm } from 'vee-validate';
import useUsers, { type UserRole } from '@composables/useUsers';
import { useI18n } from 'vue-i18n';
import { useConfirm } from 'primevue/useconfirm';

const props = defineProps<{
  userId?: number;
  isAdminEdit: boolean;
}>();

const { validate, meta, setValues } = useForm();
const {
  user: fetchedUser,
  fetchUser,
  createUser,
  updateUser,
  requestPasswordReset,
  organizations: availableOrganizations,
  fetchOrganizations,
  invalidateUsers,
  allUserRoles,
  getRoleName,
  shouldBeRestrictedRole,
  isAdminRoleSelected,
} = useUsers(props.userId);
const { t } = useI18n();
const confirm = useConfirm();
const isCreating = computed(() => !props.userId);
const user = ref<TmUser>({
  username: '',
  email: '',
  roles: [],
  organization: 'Roadtwin',
});
const passwordConfirmation = ref<string>();

const selectedUserRoles = ref<UserRole[]>([]);

const filteredOrganizations = ref<string[]>([]);
const searchForOrganization = (query: string) => {
  filteredOrganizations.value = availableOrganizations.value.reduce((filtered: string[], org) => {
    if (org.name?.toLowerCase().includes(query.toLowerCase())) filtered.push(org.name);
    return filtered;
  }, []);
};

const saveUser = async () => {
  await validate();
  if (!meta.value.valid) return;

  const userData: TmUser = {
    ...user.value,
    roles: selectedUserRoles.value.map((role) => role.roleKey),
    organization: user.value.organization || null,
  };
  isCreating.value ? createUser(userData) : updateUser(userData);
};

const sendPasswordRequest = () => {
  confirm.require({
    message: t('users.confirm sending password request', { email: user.value.email }),
    header: t('users.send password request'),
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      requestPasswordReset({ email: user.value.email });
    },
  });
};

const userRolesOptions = computed<UserRole[]>(() => {
  const isDisabled = (key: UserRole['roleKey']) => shouldBeRestrictedRole(key, selectedUserRoles.value, 'disabled');
  const isHidden = (key: UserRole['roleKey']) => shouldBeRestrictedRole(key, selectedUserRoles.value, 'hidden');
  return allUserRoles.reduce((options: UserRole[], role) => {
    if (!isHidden(role.roleKey)) options.push({ ...role, disabled: isDisabled(role.roleKey) });
    return options;
  }, []);
});

watch(selectedUserRoles, (newRoles, prevRoles) => {
  // magically select some roles if admin role was either selected or deselected - requested in TraMod-1178
  const adminWasRemoved = isAdminRoleSelected(prevRoles) && !isAdminRoleSelected(newRoles);
  const adminWasAdded = !isAdminRoleSelected(prevRoles) && isAdminRoleSelected(newRoles);
  if (!adminWasAdded && !adminWasRemoved) return;
  selectedUserRoles.value = userRolesOptions.value.filter((r) => adminWasAdded || r.roleKey !== 'admin');
});

onMounted(async () => {
  if (props.isAdminEdit) fetchOrganizations();
  if (isCreating.value) return;
  invalidateUsers(props.userId);
  await fetchUser();
  user.value = { ...fetchedUser.value };
  setValues(fetchedUser.value);
  selectedUserRoles.value = userRolesOptions.value.filter((r) => user.value.roles?.includes(r.roleKey));
});
</script>

<style scoped>
.block-heading {
  display: block;
  margin-bottom: 1rem;
  font-weight: bold;
}
.organizations-field {
  width: 100%;
}
</style>
